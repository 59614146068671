import React from 'react';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import PropTypes from 'prop-types';

import { useSharedDataActions, useSharedDataState } from 'store/sharedData';

import CookiePopup from 'components/CookiePopup';

const CitiesModal = dynamic(() => import('components/CitiesModal'));
const SearchModal = dynamic(() => import('components/SearchModal'), { ssr: false });
const AuthenticationModal = dynamic(() => import('components/Authentication'), { ssr: false });

const DynamicFooter = dynamic(() => import('components/Footer'));
const DynamicNavbar = dynamic(() => import('components/Navbar'));

const Layout = ({ children, componentTheme }) => {
  const { isAuthOpen, isCitiesOpen, isSearchOpen, needsVerifiedUser, navbar, redirectUrl } =
    useSharedDataState();
  const { closeAuth, closeCities, closeSearch } = useSharedDataActions();

  return (
    <>
      <Head>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <link rel="manifest" href="/manifest.json" />
        <link href="/static/favicon-16x16.png?v=1.0" rel="icon" type="image/png" sizes="16x16" />
        <link href="/static/favicon-32x32.png?v=1.0" rel="icon" type="image/png" sizes="32x32" />
        <link rel="apple-touch-icon" href="/static/apple-touch-icon.png?v=3.0" />
        <meta name="msapplication-TileColor" content="#37424a" />
        <meta name="msapplication-TileImage" content="/static/ms-icon-144x144.png?v=1.0" />
        <meta name="theme-color" content="#37424a" />
        <link type="text/plain" rel="author" href="/humans.txt" />
        <meta name="facebook-domain-verification" content="7nek3utipaxvak7t2f06w31cxw52gu" />
      </Head>
      <DynamicNavbar setRef theme={navbar.theme} {...componentTheme.navbar} />
      <CitiesModal isOpen={isCitiesOpen} onModalClose={closeCities} />
      {children}
      {isAuthOpen && (
        <AuthenticationModal
          isOpen={isAuthOpen}
          onModalClose={closeAuth}
          needVerification={needsVerifiedUser}
          redirectUrl={redirectUrl}
        />
      )}
      <SearchModal isOpen={isSearchOpen} onModalClose={closeSearch} />
      <DynamicFooter {...componentTheme.footer} />
      <CookiePopup />
    </>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  componentTheme: PropTypes.shape({
    navbar: PropTypes.shape({
      theme: PropTypes.string,
      backgroundColor: PropTypes.string,
    }),
    footer: PropTypes.shape({
      hasSeparator: PropTypes.bool,
    }),
  }),
};

Layout.defaultProps = {
  componentTheme: {},
};
