var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"b2cweb@1.44.32"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const isEnabled = process.env.NEXT_PUBLIC_SENTRY_IS_ENABLED === 'true';
if (isEnabled)
  Sentry.init({
    dsn: SENTRY_DSN || 'https://10f7d3c110154b70948725f6f7a98d5d@o378685.ingest.sentry.io/5202480',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    enabled: isEnabled,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    environment: process.env.NEXT_PUBLIC_PROJECT_ENV,
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (error && error.shouldNotReportToSentry) {
        return null;
      }
      return event;
    },
  });
