import React, { useContext, useMemo, useReducer } from 'react';

const SharedDataContextState = React.createContext(
  new Error('useSharedDataState must be used within SharedDataProvider!')
);

const SharedDataContextActions = React.createContext(
  new Error('useSharedDataActions must be used within SharedDataProvider!')
);

SharedDataContextState.displayName = 'SharedDataContextState';
SharedDataContextActions.displayName = 'SharedDataContextActions';

const reducer = (state, action) => {
  return typeof action === 'function' ? action(state) : { ...state, ...action };
};

const initialState = {
  isAuthOpen: false,
  isCitiesOpen: false,
  isSearchOpen: false,
  shouldShowCities: false,
  shouldShowSearch: false,
  needsVerifiedUser: false,
  bookingHoldToken: null,
  bookingErrorMessage: null,
  navbar: {
    theme: undefined,
    boundingClientRect: 0,
  },
  redirectUrl: null,
};

export const SharedDataProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(
    () => ({
      openAuth: () => dispatch({ isAuthOpen: true }),
      openCities: () =>
        dispatch(prev => (prev.shouldShowCities ? { ...prev, isCitiesOpen: true } : prev)),
      openSearch: () => dispatch({ isSearchOpen: true }),
      closeAuth: () => dispatch({ isAuthOpen: false }),
      closeCities: () => dispatch({ isCitiesOpen: false }),
      closeSearch: () => dispatch({ isSearchOpen: false }),
      setNavbarTheme: theme => dispatch({ navbar: { theme } }),
      setNavbarBoundingClientRect: boundingClientRect =>
        dispatch({ navbar: { boundingClientRect } }),
      setBookingHoldToken: bookingHoldToken => dispatch({ bookingHoldToken }),
      setBookingErrorMessage: bookingErrorMessage => dispatch({ bookingErrorMessage }),
      dispatch,
    }),
    [dispatch]
  );

  return (
    <SharedDataContextActions.Provider value={actions}>
      <SharedDataContextState.Provider value={state} {...props} />
    </SharedDataContextActions.Provider>
  );
};

export function useSharedDataState() {
  const context = useContext(SharedDataContextState);
  if (context instanceof Error) throw context;
  return context;
}

export function useSharedDataActions() {
  const context = useContext(SharedDataContextActions);
  if (context instanceof Error) throw context;
  return context;
}
